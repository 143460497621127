import Cookies from "./cookies";
import { getConfig } from "./config";
import {
    CookieConsentClient,
    CookieConsentState,
    CookieSettings,
} from "./types";

const config = getConfig();

const defaultCookieSettings: CookieSettings = {
    required: false,
    optional: false,
};

export const isValidState = (data: any): boolean =>
    typeof data === "object" &&
    typeof data.t === "number" &&
    typeof data.required === "boolean" &&
    typeof data.optional === "boolean";

export const setState = (data: CookieConsentState): boolean => {
    if (!isValidState(data)) {
        return false;
    }
    Cookies.set(
        config.cookieName,
        JSON.stringify({
            ...defaultCookieSettings,
            ...data,
        }),
        config
    );
    return true;
};

export const getState = (): CookieConsentState | null => {
    try {
        const dataEncoded: string = Cookies.get(config.cookieName);
        if (dataEncoded === "") {
            return null;
        }
        const data: CookieConsentState = JSON.parse(
            Cookies.get(config.cookieName)
        );
        return isValidState(data) ? data : null;
    } catch (err) {
        return null;
    }
};

export const getCookieSettings = (): CookieSettings => {
    const data = getState();
    if (data !== null && typeof data.t === "number") {
        return {
            ...defaultCookieSettings,
            ...data,
        };
    }
    return {
        ...defaultCookieSettings,
    };
};

export const hasConsented = (key?: "required" | "optional"): boolean => {
    switch (key) {
        case "optional":
        case "required":
            return getCookieSettings()[key];
        case undefined:
        default:
            return getCookieSettings().required;
    }
};

export const consent = (prefs?: CookieSettings): boolean => {
    return setState({
        ...defaultCookieSettings,
        ...prefs,
        t: Date.now(),
    });
};

export const cookieConsentClient: CookieConsentClient = {
    getState,
    getCookieSettings,
    hasConsented,
    consent,
};

export default cookieConsentClient;
